import Route from '@ember/routing/route';
import { action } from '@ember/object';

export default class AuthenticatedKasesManageFinancialsSelectionEditPackageRoute extends Route {
  model(params) {
    return this.store.findRecord('v2/selection-package', params.selection_package_id);
  }

  @action
  refreshModel() {
    this.refresh();
  }

  @action
  redirectToSelection(model) {
    this.transitionTo('authenticated.kases.manage.financials.selection', {
      queryParams: { selection_id: model.selection.get('id') }
    });
  }
}
