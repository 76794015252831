import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'crakn/config/environment';

export default class KasesEditTfeFormsFilledController extends Controller {
  @service api;
  @service flashes;
  @service intl;
  @service session;

  @tracked page = 1;
  @tracked refillAllEnabled = true;
  @tracked showVersionHistory = null;

  archived = false;
  hostUrl = `${config.host}`;
  queryParams = ['archived', 'page', 'showVersionHistory'];

  get generatedDocumentsExist() {
    return this.model.length > 0;
  }

  get formInProgress() {
    const inProgressForms = this.model.filter(
      (form) =>
        form.get('status') === 'processing' ||
        form.get('status') === 'pending' ||
        form.get('status') === 'refilling'
    );
    return inProgressForms.length > 0;
  }

  handleError() {
    this.flashes.addError(
      this.intl.t(
        'authenticated.kases.manage.edit.tfe.forms.filled.messages.error'
      )
    );
  }

  handleDeleteSuccess() {
    this.flashes.addSuccess(
      this.intl.t(
        'authenticated.kases.manage.edit.tfe.forms.filled.messages.delete.success'
      )
    );
  }

  async refillForm(form) {
    form.set('status', 'processing');

    const response = await this.api.json.post('tfe/generated_documents', {
      body: {
        remote_id: form.remoteId,
        remote_document_params: form.remoteDocumentParams,
        revision: { id: form.revision.get('id') },
        document: { id: form.document.get('id') },
        kase_id: this.kase.id,
        archive_existing_id: form.id
      }
    });

    if (response.ok) {
      this.formQuery = '';
      this.send('refreshModel');
    } else {
      this.flashes.addError(
        this.intl.t('authenticated.admin.manage.tfe.forms.messages.error')
      );
    }
  }

  toggleRefillAllEnabled() {
    this.refillAllEnabled = !this.refillAllEnabled;
  }

  @action
  async checkForUploadCompleted(form) {
    await this.store
      .findRecord('tfe/generatedDocument', form.id)
      .catch(() => {
        this.handleError();
      });
  }

  @action
  prepareToRefillForm(form) {
    if (this.kase.get('hasDirtyAttributes')) {
      this.kase
        .save()
        .then(() => {
          this.refillForm(form);
        })
        .catch(() => {
          this.handleError();
        });
    } else {
      this.refillForm(form);
    }
  }

  @action
  async refillAll() {
    const canUseEsign = this.session.currentClient.can_use_tfe_esign;
    const hasPendingForms = this.model.toArray().some(
      (form) => form.get('esignEnabled') && form.get('hasEsignableRoles') && form.get('signingStatus') === 'pending'
    );

    if (canUseEsign && hasPendingForms) {
      if (
        confirm(
          this.intl.t(
            'authenticated.kases.manage.edit.tfe.forms.filled.messages.refillAllEsignForm'
          )
        )
      ) {
        this.performRefillAll();
      }
    } else {
      this.performRefillAll();
    }
  }

  @action
  async performRefillAll() {
    this.refillAllEnabled = false;

    this.model.forEach((form) => {
      form.set('status', 'refilling');
    });

    await this.api.json.post('tfe/generated_documents/kase_refill_all', {
      body: {
        kase_id: this.kase.id
      }
    });

    this.send('refreshModel');
    this.formQuery = '';
  }

  @action
  removeGeneratedDocument(document) {
    document
      .destroyRecord()
      .then(() => {
        this.send('refreshModel');
        this.handleDeleteSuccess();
      })
      .catch(() => {
        this.handleError();
      });
  }
}
